import { reactive } from "vue";

export default {
  props: {
    modelValue: {},
    type: {},
    list: {}, // prevent inheritence of list="" on element
  },
  data: function () {
    return {
      passwordVisible: false,
    };
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    inputAttrs() {
      let attrs = { ...this.$attrs };
      delete attrs.list;
      return attrs;
    },
    finalType() {
      if (this.passwordVisible) {
        return "text";
      } else {
        return "password";
      }
    },
  },
};
