<template>
  <input v-model="value" :type="finalType" v-bind="$attrs"  />

  <icon
    class="field__icon field__icon--end"
    icon="eye"
    :size="iconSize"
    :theme="iconTheme"
    style="pointer-events: auto; cursor: pointer"
    @click="passwordVisible = !passwordVisible">
  </icon>
</template>

<script>
import PasswordInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/passwordInput.js";

export default {
  mixins: [PasswordInputMixin],
  props: {
    iconTheme: {
      type: [String],
      default: "gray-4",
    },
    iconSize: {
      type: [Number],
      default: 1.2,
    },
  },
};
</script>

<style scoped lang="scss"></style>
